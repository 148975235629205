//全站統一
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=Raleway:wght@300;500;700&display=swap');

body{
  font-family: 'Noto Sans TC', sans-serif;
  margin: 0;
  letter-spacing: 1px;
  background: #FFF;
  color: #545454 !important;


  //為了react modal打開的時候
  &.modal-open{
    overflow: hidden;
    // padding-right: 8px !important;

  }

  .modal{
    // backdrop-filter: blur(2px);
  }

  .modal-backdrop{
    // background-color: #868686;
    &.show{
      opacity: 0.7
    }
  }

  &:not(.modal-open){
    padding-right: 0px !important;
  }

  img{
    width: 100%;
    height: auto;
    user-select: none;
    -webkit-touch-callout: none;
    touch-action: manipulation;
  }

  p{
    margin: 0;
    display: inline-block;
  }

  a.nodec{
    text-decoration: none;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  background: #f0f2f4;
  border-radius: 4px;

  &:horizontal{
    height: 6px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  &:horizontal{
    border: none;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #385169;
  border-radius: 2px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #385169;
}


@media (max-width: 1024px){
  body{
    &.modal-open{
      padding-right: 0px !important;
    }
  }
}

@media print {
  .clickOutside {
    display: none;
  }
}
